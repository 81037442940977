<template>
  <!-- 引入样式 -->
  <div class="sortProduct">
    <NavHeader />

    <div class="content_container">

      <!-- <el-breadcrumb
        separator-class="el-icon-arrow-right "
        style='padding:5px;margin-bottom:10px'
      >
        <el-breadcrumb-item>
          <span
            class="hover"
            @click="all"
          >全部 </span>
        </el-breadcrumb-item>
        <el-breadcrumb-item
          @click="choseSort(sort0)"
          v-if='sort0.name'
        ><span
            class="hover"
            @click="choseSort(sort0)"
          > {{sort0.name}}</span> </el-breadcrumb-item>

        <el-breadcrumb-item
          @click="choseSort(sort)"
          v-if='sort.name'
        ><span
            class="hover"
            @click="choseSort(sort)"
          > {{sort.name}}</span> </el-breadcrumb-item>
        <el-breadcrumb-item v-if='searchData.brand_id'> 品牌：{{brand}} </el-breadcrumb-item>
      </el-breadcrumb> -->
      <el-breadcrumb
        separator-class="el-icon-arrow-right "
        style='padding:5px;margin-bottom:10px'
      >

        <el-breadcrumb-item v-if='sort0.name'><span class=" "> {{sort0.name}}</span> </el-breadcrumb-item>

        <el-breadcrumb-item v-if='sort.name'><span class="hover " @click="clickSort()"> {{sort.name}}</span> </el-breadcrumb-item>
        <el-breadcrumb-item v-if='searchData.brand_id'> 品牌：{{brand}} </el-breadcrumb-item>
      </el-breadcrumb>
      <div>
        <!-- <el-row class="box">
          <el-col
            :span="1"
            class="name"
          > 品类:</el-col>
          <el-col :span="23">

            <ul class='Category'>
              <li
                v-for="(item,index) in Category"
                :key="index"
                @click="choseCategory(item,index)"
                :class="{red:cindex==index}"
              >{{ item.cate_name }}
                <i :class="{'el-icon-arrow-down':cindex==index,'el-icon-arrow-right':cindex!=index}"></i>
              </li>
            </ul>
            <div
              class="Category_c"
              v-if='children.length'
            >
              <div
                v-for="(item1, index1) in children"
                :key="index1"
                class="Category_cc"
              >
                <p class="tit"><span
                    @click="toSort_cc(item1,index1)"
                    class="hover"
                    :class="{red:cindex1==index1}"
                  >{{ item1.cate_name }}</span></p>
                <dl>
                  <dd
                    class="hover"
                    :class="{red:item1.index==index2}"
                    v-for="(item2, index2) in item1.cc"
                    :key="index2"
                    @click="toSort_ccc(item2,index2,item1)"
                  >{{ item2.cate_name }}</dd>
                </dl>
              </div>
            </div>
          </el-col>
        </el-row> -->

        <el-row
          class="box"
          v-if='listDataBrand.length||searchData.brand_id'
        >
          <el-col
            :span="1"
            class="name"
          > 品牌:</el-col>
          <el-col :span="23">
            <ul class="listBrand">
              <li
                :class="{red:idx==index}"
                v-for="(item,index) in listDataBrand"
                :key="index"
                @click="chosebrand(item,index)"
              >{{item.value}}</li>
            </ul>
          </el-col>
        </el-row>
        <el-row class="box">
          <el-col
            :span="1"
            class="name"
            style="line-height:30px"
          >价格:</el-col>

          <el-col :span="23">
            <ul class="listPrice ">
              <li
                v-for="(item,index) in listDataPrice"
                :class="{red:idx2==index}"
                @click="choosePrice(item,index)"
                :key='index'
              >

                <span v-if='item.start==500'>
                  500以上
                </span>
                <span v-else> {{item.start}}-{{item.end}} </span>
              </li>
              <li>
                <el-input
                  placeholder=""
                  v-model="searchData.pstart"
                  style="width:80px"
                ></el-input>-
                <el-input
                  placeholder=""
                  v-model="searchData.pend"
                  style="width:100px"
                ></el-input>
                <el-button
                  type=""
                  @click="getProduct( )"
                >确定</el-button>
              </li>

            </ul>
          </el-col>
        </el-row>
      </div>

      <div class="filtrate">
        <p
          @click="getProduct(0)"
          :class="{red2:number==0}"
        >综合</p>
        <p
          @click="getProduct(1)"
          :class="{red2:number==1}"
        >销量</p>
        <p
          @click="getProduct(2)"
          :class="{red2:number==2}"
        >价格<i :class="{'el-icon-top': searchData.price_pr=='asc','el-icon-bottom': searchData.price_pr=='desc'}"></i></p>
        <p
          @click="getProduct(3)"
          :class="{red2:number==3}"
        >推荐 </p>

        <div
          class="sorter"
          style="float: right"
        ></div>
      </div>
      <div class="sortlist">
        <ol>
          <li
            class="item"
            v-for="(item, index) in goodList"
            :key="index"
          >
            <div class="item-img-wraper">
              <img
                class="item-img"
                :src="$config.baseUrl + item.files_path"
                @click="$util.toDetail(item.get_shop.shop_type, item.goods_id )"
              />
            </div>
            <p
              class="item-name"
              @click="$util.toDetail(item.get_shop.shop_type, item.goods_id )"
            >

              {{ item.goods_name }}
            </p>
            <div
              class="item-price"
              v-if="item.isprice == 2"
            >
              <span>
                ￥{{ parseFloat(item.min_price).toFixed(2) }} - ￥{{
                    parseFloat(item.max_price).toFixed(2)
                  }}
              </span>
            </div>
            <div
              class="item-price"
              v-if="item.isonly == 1 && item.isprice == 1"
            >
              <span>￥{{ parseFloat(item.sales_price/ 100).toFixed(2)  }}</span>
            </div>
            <div
              class="item-price"
              v-if="item.isonly == 2 && item.isprice == 1&&item.num_price == null"
            >
              <span>￥{{  item.spec_min_price }}起</span>
            </div>
            <div
              class="item-price"
              v-if="item.isonly == 2 && item.isprice == 1 &&item.num_price"
            >
              <span>￥{{  JSON.parse(item.num_price)[0].price[0].price  }}以下</span>
            </div>
            <div
              class="shop-name"
              @click="$util.toShop(item.get_shop.shop_type, item.get_shop.shop_id )"
            >
              <span>
                {{ item.get_shop.shop_name }} </span>
              <i>进店</i>
            </div>

          </li>
        </ol>
      </div>
      <el-pagination
        @current-change="currentChange"
        :current-page.sync="searchData.page"
        :page-sizes="[10, 20, 30, 40]"
        :page-size.sync="searchData.limit"
        :total="total"
      >
      </el-pagination>
    </div>
  </div>
</template>
<script>
import NavHeader from "@/components/layout/nav-header";

export default {
  components: {
    NavHeader
  },
  data() {
    return {
      number: "",
      listDataSort: [],
      brand: undefined,
      cindex: undefined,
      cindex1: undefined,
      cindex2: undefined,
      idx: undefined,
      idx2: undefined,

      sort: { id: "", name: "" },
      sort0: { id: "", name: "" },
      listDataPrice: [
        { start: 0, end: 99 },
        { start: 100, end: 199 },
        { start: 200, end: 299 },
        { start: 300, end: 399 },
        { start: 400, end: 499 },
        { start: 500, end: 1000000 }
      ],
      listDataBrand: [],
      searchData: {
        pstart: "",
        pend: "",
        brand_id: "",
        catid: "",
        page: 1,
        limit: 25,
        goodslabel: ""
      },
      total: 0,

      isErr: false,
      timer: null,

      car: false,
      num: "0",
      listIndex: 0,
      name: "",
      goodList: [],
      saleList: [],
      priceList: [],
      Category: [],
      children: [],
      child: [],
      navList: [
        {
          name: "全部"
        },
        {
          name: "销量"
        },
        {
          name: "价格"
        }
      ]
    };
  },
  computed: {},

  mounted() {
    this.searchData = Object.assign(this.searchData, this.$route.query);
    if (this.$route.query.goodsname) {
      this.$store.commit("setHistorySeach", this.searchData.goodsname);
    }
    if (this.$route.query.goodslabel == 3) {
      this.number = 3;
    }
    this.currentChange();

    this.getCategory();

    if (this.$route.query.catid) {
      this.getBrand(this.$route.query.catid);
    }

    this.sort.id = this.$route.query.catid;
    this.sort.name = this.$route.query.sort;

    this.sort0.id = this.$route.query.catid0;
    this.sort0.name = this.$route.query.sort0;
    //产品分类列表
    this.category();
  },
  methods: {
    clickSort(){

      this.searchData = Object.assign(this.searchData, this.$route.query);
        this.searchData.brand_id=''
        this.currentChange();
    },
    category() {
      this.$get("/home/goodsCategory").then(res => {
        this.Category = res;
      });
    },

    //获取分类联动品牌

    getCategory() {
      //获取品类 listDataSort
      this.$get("home/goodsCategory").then(res => {
        res.forEach(a => {
          this.listDataSort.push({
            value: a.id,
            label: a.cate_name,
            children: a.cc.map(b => {
              return { value: b.id, label: b.cate_name };
            })
          });
        });
      });
    },
    choseCategory(item, index) {
      this.children = item.cc;

      // if ( !((this.children.length - 1) % 2 == 0))

      // { this.children[this.children.length - 1].offsetWidth =100% }

      this.children.forEach(a => {
        this.$set(a, "index", undefined);
      });
      this.toSort_c(item, index);
    },
    toSort(item1, index) {
      this.idx = index;
      this.searchData.brand_id = "";

      this.searchData.catid = item1.value;
      this.sort.name = item1.cate_name;
      this.currentChange();
    },
    toSort_c(item, index) {
      if (index == 0) {
      }
      this.cindex = index;
      this.searchData.brand_id = "";
      this.searchData.catid = item.value;
      this.sort.name = item.cate_name;
      this.currentChange();
      this.getBrand(item.id);
    },
    toSort_cc(item, index) {
      this.cindex1 = index;
      this.searchData.brand_id = "";
      this.searchData.catid = item.value;
      this.sort.name = item.cate_name;
      this.currentChange();
      this.getBrand(item.id);
    },
    toSort_ccc(item, index, item1) {
      item1.index = index;
      this.searchData.brand_id = "";
      this.searchData.catid = item.value;
      this.sort.name = item.cate_name;
      this.currentChange();
      this.getBrand(item.id);
    },
    choseSort(sort) {
      this.searchData.brand_id = "";

      this.searchData.catid = sort.id;
      this.currentChange();
    },
    all() {
      this.searchData.brand_id = "";
      this.sort = {};
      this.sort0 = {};
      this.searchData.catid = "";
      (this.cindex = undefined),
        (this.cindex1 = undefined),
        (this.cindex2 = undefined),
        (this.idx = undefined),
        this.currentChange();
    },
    choosePrice(item, index) {
      this.idx2 = index;
      this.searchData.pstart = item.start;
      this.searchData.pend = item.end;

      this.currentChange();
    },
    chosebrand(item, index) {
      this.idx = index;
      this.brand = item.value;
      this.searchData.brand_id = item.lable;

      this.currentChange();
    },
    //获取分类联动品牌

    getBrand(id) {
      console.log("idid", id);
      this.$post("admin/goodsCate/brand", { id: id, status: 1 }).then(res => {
        this.listDataBrand = res.map(item => {
          return {
            lable: item.id,
            value: item.name
          };
        });

        console.log("listDataBrand", this.listDataBrand);
      });
    },
    getProduct(index) {
      this.number = index;
      if (index == 0) {
        this.searchData.price_pr = undefined;
        this.searchData.sale_num_pr = undefined;
        this.searchData.goodslabel = undefined;
      }
      if (index == 1) {
        this.searchData.price_pr = undefined;
        this.searchData.sale_num_pr =
        this.searchData.sale_num_pr == "asc" 
          // this.searchData.sale_num_pr == "desc" ? "asc" : "desc";
      }
      if (index == 2) {
        this.searchData.sale_num_pr = undefined;
        this.searchData.price_pr =
          this.searchData.price_pr == "asc" ? "desc" : "asc";
      }
      if (index == 3) {
        this.searchData.sale_num_pr = undefined;
        this.searchData.price_pr = undefined;
        this.searchData.goodslabel = 3;
      }

      this.currentChange();
    },

    //获取全部产品
    getGoods() {
        this.$get("home/goods", {
        member_id: this.$store.state.member.id,
        money: this.$route.query.money,
        shop_id: this.$route.query.id
      }).then(res => {
        this.goodList = res.items.data;
        console.log(" this.goodList", this.goodList);
      });
    },

    currentChange() {
     
      // this.searchData.pcatid = this.$route.query.sort0;
      this.searchData.sort0 = "";
      this.searchData.catid0 = "";

      console.log("this.searchData", this.searchData);
      this.$get("/home/goods", this.searchData).then(res => {
        this.goodList = res.items.data;
        this.total = res.items.total;
        
        console.log("goodList", this.goodList);
      });
    }
  },
  created() {}
};
</script>

<style scoped lang="scss">
@import "@/assets/styles/mixins";
.red2 {
  color: red;
  border: 1px solid red !important;
}
.listPrice {
  @include row;
  line-height: 30px;
  li:hover {
    cursor: pointer;
  }
  li {
    margin-right: 30px;
  }
  li:nth-child(8) {
    width: 350px;
    @include row;
  }
}
.sortProduct {
  background: #fff;
}
::v-deep .el-collapse-item__header {
  display: inline-block;
}
::v-deep .el-collapse {
  display: inline-block;
  float: left;
}
.box {
  padding: 10px;
  margin-bottom: 5px;
  border-top: 1px dashed #dad8d6;
}
.Category {
  li {
    font-size: 14px;

    display: inline-block;
    margin-right: 20px;
  }
  li:hover {
    color: red;
    cursor: pointer;
  }
}
.Category_c {
  font-size: 12px;
  padding: 10px;
  color: #888;
  p {
  }
  // .Category_cc{width: 50%; float: left;}
  .tit {
    font-weight: bold;
    margin-bottom: 10px;
    font-size: 13px;
    color: #757575;
  }
  dl {
  }
  dd {
    display: inline-block;
    margin: 5px;
  }
}
.dropdown {
  p {
    font-weight: bold;
  }
  padding: 20px;
  dl {
    margin-bottom: 10px;
  }
  dd {
    color: #888;
    display: inline-block;
    margin: 5px 10px;
  }
  dd:hover {
    color: red;
    cursor: pointer;
  }
  ul {
    li {
      display: inline-block;
    }
  }
}
.el-pagination {
  text-align: center;
  margin-top: 20px;
}

.red {
  color: red !important;
}
.name {
}
.hover {
  cursor: pointer;
}
.listBrand li {
  display: inline-block;
  margin: 5px;
  margin-top: 0;
  color: #888;
}
.listBrand li:hover {
  color: red;
  cursor: pointer;
}

.filtrate {
  margin: 10px 0;
  padding: 8px 5px 0 5px;
  position: relative;
  z-index: 10;
  background: #faf9f9;
  color: #806f66;
  line-height: 20px;

  p {
    margin-right: 10px;
    text-align: center;
    display: inline-block;
    margin-left: -1px;
    overflow: hidden;
    padding: 0 5px 0 5px;
    cursor: pointer;
    height: 22px;
    line-height: 20px;
    border: 1px solid #ccc;

    i {
      font-weight: 800;
    }
  }
}

.pointer {
  background: #fe6925;

  color: #fff;
  border-radius: 5px;
}
.topbar {
  background: #fff;
  width: 100%;
  text-align: center;
  color: #757575;
  border-bottom: #ccc 1px solid;

  height: 50px;
  line-height: 50px;

  ul {
    padding-top: 10px;
    box-sizing: border-box;
    width: 100%;
    height: 50px;
    @include row;
    li {
      cursor: pointer;

      @include center;
      @include row;
      flex-wrap: nowrap;
      height: 30px;
      line-height: 30px;
      width: 80px;
      text-align: center;
      margin: 0 20px;
      @include center;
    }
  }
}
.Detail {
  background: #f5f5f5;
}
.sortlist {
  width: 100%;
  background: #fff;
  @include space-between;

  ul {
    background: #f0f0f0;
    width: 300px;

    li {
      padding: 10;
    }
  }
  ol {
    flex: 1;
    margin: 0 -5px;
    .item {
      background: #fff;
      box-sizing: border-box;
      padding: 10px;

      text-align: center;
      width: 226px;
      margin: 5px;
      border: 1px solid #eee;

      float: left;
      position: relative;

      .item-img-wraper {
        overflow: hidden;

        height: 190px;

        box-sizing: border-box;

        .item-img {
          width: 100%;
          height: 100%;
        }
      }
      .shop_name {
        height: 30px;
        text-align: left;
        line-height: 30px;
        @include space-between;

        p {
          color: #999;
          width: 80%;
          @include ellipsis;
        }
      }
      .item-name {
        height: 50px;
        line-height: 25px;

        text-align: left;
        font-size: 16px !important;
        word-break: break-all;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
      }
      .item-price {
        font-size: 24px !important;
        @include space-between;

        span {
          font-size: 16px !important;
        }
        color: red;
      }
      .shop-name {
        @include space-between;
        padding: 5px 0;
        text-align: left;
        color: #999;
        flex: 1;
        span {
          @include ellipsis;
        }
        i {
          width: 40px;
          text-align: right;
          color: #fe6925;
        }

        cursor: pointer;
        font-size: 12px;
      }
      .car {
        text-align: left;
        margin-right: 10px;
        color: #999;
        line-height: 25px;
        i {
          vertical-align: middle;
          font-size: 22px;
          color: #999;
        }
      }
    }
  }
  ol::after {
    content: "";
    display: block;
    clear: both;
    width: 30%;
  }
}
.banner {
  width: 100%;
  img {
    width: 100%;
    height: 400px;
  }
}
.product-info {
  background: #fff;
  padding: 20px;
  line-height: 25px;
  text-align: left;
  margin-bottom: 20px;
}
</style>
 